<template>
  <div class="container">
    <div class="change-password-complete" :class="{'padding-has-caution': isHasCaution}">
      <h3 class="title">メールアドレス変更</h3>

      <div class="change-password-complete__container step">

        <div class="step__register">

          <div class="step__block">
            <h4>メールアドレスを変更しました。</h4>

            <a class="btn__register" @click.prevent="openPageNative('Profile.Mypage')">
              マイページへ
              <ChevronRightIcon size="24"/>
            </a>
          </div>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
// Icon
import { ChevronRightIcon } from 'vue-feather-icons'
import store from '@/store'
import { checkWebview } from '@/utils/helpers'
import Cookie from 'js-cookie'
import Common from '@/mixins/common.mixin'
import { mapState } from 'vuex'

export default {
  name: 'Complete',

  mixins: [Common],

  components: {
    ChevronRightIcon
  },

  beforeRouteEnter (to, from, next) {
    const params = {
      token: to.params.token
    }
    if (checkWebview()) {
      next()
    } else {
      store.dispatch('user/userCheckChangeEmail', params).then(result => {
        if (result.status === 200) {
          store.commit('auth/SET_TOKEN', result.data.data.access_token)
          next()
        } else next({ name: 'Expired' })
      })
    }
  },

  mounted () {
    // hide app's loading after page render
    if (Common.methods.checkWebview()) {
      Common.methods.triggerEventApp('PAGE_RENDERED', {})
    }
  },

  computed: {
    ...mapState('auth', ['profile']),
    isHasCaution () {
      return !this.checkWebview() && ((this.profile && this.profile.skip_regis_info) || this.checkMobile())
    }
  },

  created () {
    if (this.checkWebview() && !Cookie.get('bearer_token')) {
      this.openPageNative('Login')
    }
  }
}
</script>

<style lang="scss" scoped>
.change-password-complete {
  padding: 90px 0;
  @media #{$info-phone} {
    padding: 85px 0;
  }
  &.padding-has-caution {
    @media #{$info-phone} {
      padding: 120px 0 85px;
    }
  }
  .title {
    font-family: $font-family-title;
    color: $default-green;
    @include font-size(36px);
    font-weight: 400;
    text-align: center;
    margin-bottom: 40px;
    @media #{$info-phone} {
      font-size: 32px;
      margin-bottom: 30px;
    }
  }

  &__container {
    border: 6px solid #E7E5DE;
    border-radius: 6px;
    background-color: #FAFAFA;
    @media #{$info-phone} {
      border: 3px solid #E7E5DE;
      border-left: none;
      border-right: none;
      border-radius: 0;
    }
  }

  .step {
    &__block {
      border-radius: 6px;
      display: flex;
      flex-direction: column;
      padding: 22px;
      @media #{$info-phone} {
        padding: 18px;
      }
    }

    h4 {
      text-align: center;
      font-weight: 500;
      color: $default-green;
      @include font-size(24px);
      margin-bottom: 35px;
      @media #{$info-phone} {
        font-size: 20px;
        margin-bottom: 20px;
      }
    }

    .btn__register {
      display: flex;
      align-items: center;
      justify-content: center;
      max-width: 360px;
      width: 100%;
      border-radius: 6px;
      color: #ffffff;
      background: #6F8784;
      font-family: $font-family-title;
      @include font-size(18px);
      padding: 14px;
      margin: auto;
      cursor: pointer;

      @media #{$info-phone} {
        font-size: 16px;
        padding: 10px;
        max-width: 100%;
      }

      svg {
        margin-left: 5px;
      }
    }
  }
}
@media #{$info-phone} {
  .container {
    padding: 0;
  }
}
</style>
